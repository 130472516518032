<template>
  <div class="product-images-slider">
    <!--    https://github.com/gs-shop/vue-slick-carousel/blob/master/docs/API.md#props-->
    <VueSlickCarousel
      ref="carousel"
      dotsClass="slick-dots"
      @beforeChange="change"
      :dots="true"
      v-bind="settings"
    >
      <div
        v-for="(photo, index) in photos"
        :key="index"
        class="product-images-slider__items"
      >
        <div class="product-images-slider__overlay"></div>
        <img
          :src="$getImgUrl(photo.path)"
          class="product-images-slider__item"
          alt="Foto del catálogo"
        />
      </div>

      <!--    https://github.com/gs-shop/vue-slick-carousel/blob/master/docs/API.md#slots -->
      <template #customPaging="page">
        <div
          class="circle-imatge"
          :class="{
            gris: page !== currentIndex,
            darrer: page === photos.length - 1,
          }"
        ></div>
      </template>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { mapGetters } from "vuex";
export default {
  name: "ProductCarousel",
  components: {
    VueSlickCarousel,
  },
  props: {
    photos: {
      type: Array,
      required: true,
    },
    dots: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentIndex: 0,
      settings: {
        // focusOnSelect: true,
        // verticalSwiping: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedVariant: "selectedVariant",
    }),
  },
  methods: {
    change(oldSlideIndex, newSlideIndex) {
      this.currentIndex = newSlideIndex;
      this.$emit("change", newSlideIndex);
    },
  },
  watch: {
    selectedVariant: {
      handler(newValue) {
        this.photos.forEach(
          (element, index) => { 
            if(element.path.substring(0, element.path.indexOf("?")) == newValue.imageUrl.substring(0, newValue.imageUrl.indexOf("?"))) 
            {
              console.log(element.path.substring(0, element.path.indexOf("?")))
              console.log(newValue.imageUrl.substring(0, newValue.imageUrl.indexOf("?")))
              this.$refs.carousel.goTo(index)
            }
          })
      }
    }
  }
};
</script>

<style scoped>
img {
  max-height: 80%;
  max-width: 80%;
  width: auto;
  height: auto;
  margin: 10%;
}

.circle-imatge {
  width: 12px;
  height: 12px;
  margin-right: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
  border-radius: 60px;
  background-color: $color-accent;
}

.circle-imatge.gris {
  width: 12px;
  height: 12px;
  margin-right: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
  border-radius: 60px;
  background-color: #dcdcdc;
}

.circle-imatge.gris.darrer {
  margin-right: 0px;
}
</style>
